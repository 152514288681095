.projects {
  display: grid;
  grid-template-rows: 80vh repeat(3, minmax(3rem, max-content));
  grid-template-columns:
    minmax(max-content, 1fr) [center-start] repeat(
      8,
      minmax(min-content, 20rem)
    )
    [center-end]
    minmax(2rem, 1fr);
  position: relative;
  @media only screen and (max-width: 53.13em) {
    grid-template-rows: 60vh repeat(3, minmax(3rem, max-content));
  }

  header {
    .bg-image {
      height: 100%;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: -1;
    }
    grid-column: 1 / -1;

    display: grid;
    grid-template-rows: 8rem 1fr;
    grid-template-columns:
      [full-start
      sidebar-start] minmax(max-content, 1fr) [sidebar-end
      center-start] repeat(8, minmax(min-content, 20rem)) [center-end]
      minmax(2rem, 1fr) [full-end];

    clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 0% 100%);

    aside {
      align-self: stretch;
      grid-column: sidebar-start / sidebar-end;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-row: 1 / -1;
      .back {
        background: white;
        margin-top: 2rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 5rem;
          transform: rotate(180deg);
        }
      }
    }
    nav {
      grid-column: 6 / 10;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 37.5em) {
        grid-column: 9 / -1;
        display: flex;
        flex-direction: column;
      }
      #myLinks {
        display: none;
        @media only screen and (max-width: 37.5em) {
          display: none;
        }
      }
      .mobileicon {
        display: none;
        @media only screen and (max-width: 37.5em) {
          align-self: center;
          display: block;
          font-size: 3rem;
          cursor: pointer;
          color: white;
        }
      }
      .default {
        @media only screen and (max-width: 37.5em) {
          display: none;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        & > *:not(:last-child) {
          margin-right: 4rem;
          @media only screen and (max-width: 37.5em) {
            margin-right: 0;
          }
        }
        @media only screen and (max-width: 37.5em) {
          width: 100%;
          flex-direction: column;
          align-items: stretch;
          position: absolute;
          top: 8rem;
          left: 0;
          z-index: 1000;
        }
        a {
          color: white;
        }
        li {
          font-size: 1.3rem;
          letter-spacing: 0.3rem;
          text-transform: uppercase;
          font-family: "Ubuntu", sans-serif;
          padding: 0.3rem;
          background-image: linear-gradient(
            120deg,
            transparent 0%,
            transparent 50%,
            #fff 50%
          );
          background-size: 225%;
          transition: all 0.2s ease;
          @media only screen and (max-width: 37.5em) {
            background-image: linear-gradient(
              120deg,
              #fff 0%,
              #fff 50%,
              var(--header-background) 50%
            );
            color: black;
          }
          &:hover {
            background-position: 100%;
            color: black;
          }
          @media only screen and (max-width: 66.88em) {
            font-size: 1.8rem;
          }
          @media only screen and (max-width: 37.5em) {
            text-align: center;
            border: 1px solid var(--header-background);
          }
        }
      }
    }
    main {
      grid-column: center-start / center-end;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      font-size: 6rem;
      @media only screen and (max-width: 75em) {
        font-size: 4.5rem;
      }
      @media only screen and (max-width: 53.13em) {
        font-size: 3rem;
      }
      h1 {
        line-height: 1;
        font-family: "Hind", sans-serif;
        text-align: center;
        margin-bottom: 2rem;
        color: white;
      }
    }
  }
  &__details {
    grid-column: 3 / 9;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    padding: 15rem 0;
    @media only screen and (max-width: 46.88em) {
      grid-template-columns: 1fr;
      grid-row-gap: 3rem;
    }
    &--text {
      align-self: center;
      grid-column: 1 / 2;
      display: flex;
      flex-direction: column;
      & > *:not(:last-child) {
        margin-bottom: 3rem;
      }
      p {
        font-size: 2rem;
        font-family: "Muli", sans-serif;
      }
      button {
        padding: 1rem 1.5rem;
        background: var(--header-background);
        color: black;
        font-family: "Ubuntu", sans-serif;
        border: 3px solid black;
        cursor: pointer;
      }
    }
    &--images {
      display: grid;
      grid-gap: 1rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .human {
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(6, 5vw);
      figure {
        &:nth-of-type(1) {
          grid-column: 1 / span 4;
          grid-row: 1 / span 2;
        }
        &:nth-of-type(2) {
          grid-column: 5 / -1;
          grid-row: 1 / span 2;
        }
        &:nth-of-type(3) {
          grid-column: 1 / span 3;
          grid-row: 3 / 5;
        }
        &:nth-of-type(5) {
          grid-column: 1 / span 3;
          grid-row: 5 / -1;
        }
        &:nth-of-type(4) {
          grid-column: 4 / -1;
          grid-row: 3 / -1;
        }
      }
    }
    .muslim {
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(4, 5vw);
      figure {
        &:nth-of-type(1) {
          grid-column: 1 / span 4;
          grid-row: 1 / span 2;
        }
        &:nth-of-type(3) {
          grid-column: 1 / span 3;
          grid-row: 3 / 5;
        }
        &:nth-of-type(2) {
          grid-column: 4 / -1;
          grid-row: 3 / 5;
        }
        &:nth-of-type(4) {
          grid-column: 5 / -1;
          grid-row: 1 / span 2;
        }
      }
    }
    .tv {
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(4, 5vw);
      figure {
        &:nth-of-type(1) {
          grid-column: 1 / span 4;
          grid-row: 1 / span 2;
        }
        &:nth-of-type(3) {
          grid-column: 1 / span 3;
          grid-row: 3 / 5;
        }
        &:nth-of-type(2) {
          grid-column: 4 / -1;
          grid-row: 3 / 5;
        }
        &:nth-of-type(4) {
          grid-column: 5 / 7;
          grid-row: 1 / span 2;
        }
        &:nth-of-type(5) {
          grid-column: 7 / -1;
          grid-row: 1 / span 2;
        }
      }
    }
  }
}
