.work {
  display: grid;
  grid-template-rows: 8rem 45vh repeat(3, minmax(3rem, max-content));
  grid-template-columns:
    [full-start
    sidebar-start] minmax(max-content, 1fr) [sidebar-end
    center-start] repeat(8, minmax(min-content, 20rem)) [center-end]
    minmax(2rem, 1fr) [full-end];
  align-items: center;
  position: relative;
  @media only screen and (max-width: 46.88em) {
    grid-template-rows: 8rem 40vh repeat(3, minmax(3rem, max-content));
  }

  aside {
    align-self: stretch;
    grid-column: sidebar-start / sidebar-end;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 59.38em) {
      grid-column: 1/ 5;
      grid-row: 1 / 2;
      justify-content: center;
    }
    @media only screen and (max-width: 31.88em) {
      grid-column: 1/ 4;
    }
    img {
      width: 10rem;
      transform: rotate(90deg) translateX(55%);
      @media only screen and (max-width: 59.38em) {
        transform: rotate(0deg) translate(0, 0);
      }
    }
  }
  nav {
    grid-column: 6 / 10;
    @media only screen and (max-width: 37.5em) {
      grid-column: 9 / -1;
      display: flex;
      flex-direction: column;
    }
    #myLinks {
      display: none;
      @media only screen and (max-width: 37.5em) {
        display: none;
      }
    }
    .mobileicon {
      display: none;
      @media only screen and (max-width: 37.5em) {
        align-self: center;
        display: block;
        font-size: 3rem;
        cursor: pointer;
      }
    }
    .default {
      @media only screen and (max-width: 37.5em) {
        display: none;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      & > *:not(:last-child) {
        margin-right: 4rem;
        @media only screen and (max-width: 37.5em) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 37.5em) {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        position: absolute;
        top: 8rem;
        left: 0;
        z-index: 1000;
      }
      a {
        color: black;
      }
      li {
        font-size: 1.3rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-family: "Ubuntu", sans-serif;
        padding: 0.3rem;
        background-image: linear-gradient(
          120deg,
          #fff 0%,
          #fff 50%,
          var(--header-background) 50%
        );
        background-size: 225%;
        transition: all 0.2s ease;
        &:hover {
          background-position: 100%;
        }
        @media only screen and (max-width: 66.88em) {
          font-size: 1.8rem;
        }
        @media only screen and (max-width: 37.5em) {
          text-align: center;
          border: 1px solid var(--header-background);
        }
      }
    }
  }
  header {
    align-self: stretch;
    grid-column: center-start / center-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    font-size: 6rem;
    background: var(--header-background);
    @media only screen and (max-width: 75em) {
      font-size: 4.5rem;
    }
    @media only screen and (max-width: 59.38em) {
      grid-column: full-start / full-end;
    }
    @media only screen and (max-width: 53.13em) {
      font-size: 3rem;
    }
    @media only screen and (max-width: 31.88em) {
      font-size: 2.7rem;
    }

    h1 {
      line-height: 1;
      font-family: "Hind", sans-serif;
      text-align: center;
      margin-bottom: 2rem;
    }
    button {
      padding: 1.5rem 2rem;
      font-size: 2rem;
      font-family: "Ubuntu", sans-serif;
      background: none;
      border: 3px solid black;
      cursor: pointer;
      transition: transform 0.1s ease-in-out, background-position 0.4s ease;
      background-image: linear-gradient(
        120deg,
        var(--header-background) 0%,
        var(--header-background) 50%,
        #fff 50%
      );
      background-size: 220%;

      &:hover {
        background-position: 100%;
        transform: translateY(0.4rem);
      }
    }
  }
  &__gallery {
    grid-row: 3 / 4;
    grid-column: center-start / center-end;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, minmax(max-content, 1fr));
    grid-row-gap: 10rem;
    padding: 20rem 0;
    @media only screen and (max-width: 56.25em) {
      grid-column: full-start / full-end;
    }
    @media only screen and (max-width: 48.75em) {
      padding: 15rem 3rem;
    }
    .figure_grid {
      grid-column: 2 / 6;
      @media only screen and (max-width: 48.75em) {
        grid-column: 1 / -1;
      }
    }
    figure {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      grid-auto-rows: 1fr;
      align-items: center;
      justify-items: center;
      grid-column-gap: 5rem;
      @media only screen and (max-width: 35.63em) {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        grid-row-gap: 3rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      h1 {
        font-size: 4rem;
        font-family: "Ubuntu", sans-serif;
        color: black;
      }
      p {
        color: black;
        font-size: 2.5rem;
        font-family: "Muli", sans-serif;
      }
    }
    &--human,
    &--tv {
      &__image {
        position: relative;
        grid-column: 1 / 4;
      }
      &__description {
        grid-column: 4 / -1;
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;
        grid-column-gap: 2rem;

        span {
          width: 4rem;
          height: 0.5rem;
          grid-column: 1 / 2;
          background: black;
          border-radius: 3rem;
        }
        h1 {
          grid-column: 2 / 3;
        }
        p {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
        }
      }
    }
    &--muslim {
      &__image {
        grid-column: 4 / -1;
        position: relative;
        @media only screen and (max-width: 35.63em) {
          grid-column: 1 / 4;
          grid-row: 1 / 2;
        }
      }
      &__description {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-column-gap: 2rem;
        @media only screen and (max-width: 35.63em) {
          grid-column: 1 / 4;
          grid-row: 2 / 3;
        }
        span {
          width: 4rem;
          height: 0.5rem;
          grid-column: 2 / -1;
          grid-row: 1 / 2;
          background: black;
          border-radius: 3rem;
          @media only screen and (max-width: 35.63em) {
            grid-column: 1 / 2;
          }
        }
        h1 {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          @media only screen and (max-width: 35.63em) {
            grid-column: 2 / -1;
          }
        }
        p {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
        }
      }
    }
  }
}
