* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

:root {
  --header-background: #ffd900;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px
  @media only screen and (max-width: 87.5em) {
    // 1400px
    font-size: 56.25%; // 1rem = 9px
  }
  @media only screen and (max-width: 75em) {
    //1200px
    font-size: 50%; // 1rem = 8px
  }
  @media only screen and (max-width: 66.88em) {
    //1070px
    font-size: 43.75%; // 1rem = 7px
  }
  @media only screen and (max-width: 28.34em) {
    font-size: 37.5%; // 1rem = 6px
  }
}

body {
  font-weight: 300;
  line-height: 1.6;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

a {
  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background: var(--header-background);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  z-index: 1000;
}

.emp {
  background: var(--header-background);
  padding: 0 1.5rem;
  font-family: "Pacifico", cursive;
  font-weight: 100;
  font-size: 5rem;
  @media only screen and (max-width: 53.13em) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 31.88em) {
    font-size: 3rem;
  }
}
